.khGOWT>div[data-v-20bc7d26] {
    position: relative;
    z-index: 2;
}
.gSMpdg[data-v-20bc7d26] {
    width: 100%;
    margin: auto;
    border-radius: 5px;
    position: relative;
    z-index: 0;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;
    border-top: 4px solid rgb(120, 187, 253);
}
.gSMpdg.lay[data-v-20bc7d26] {
    border-top: 4px solid rgb(250, 114, 144);
}
.leygRy[data-v-20bc7d26] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 15px;
    background: rgb(167, 216, 253);
}
.leygRy.lay[data-v-20bc7d26] {
    background: rgb(255, 214, 214);
}
.jabHrc[data-v-20bc7d26] {
    width: 100%;
    padding: 10px 10px 0px;
}
.ekmHYF[data-v-20bc7d26] {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    align-items: center;
    background: rgb(237, 240, 247);
    justify-content: space-between;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.hYVmcl[data-v-20bc7d26] {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: rgb(32, 57, 116);
    font-size: 12px;
}
.flGjPM[data-v-20bc7d26] {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 5px 5px 5px 15px;
    position: relative;
    font-size: 11px;
}
.cHDyJQ[data-v-20bc7d26] {
    font-size: 12px;
    padding-top: 1px;
    color: rgb(53, 73, 94);
}
.dRZyjJ[data-v-20bc7d26] {
    display: flex;
    padding: 1px 0px;
    color: rgb(87, 100, 113);
    font-size: 11px;
}
.cQbrHN[data-v-20bc7d26] {
    display: flex;
    margin-bottom: 1em;
    position: relative;
}
.VucgM[data-v-20bc7d26] {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-right: 10px;
    justify-content: space-between;
}
.cTudZX[data-v-20bc7d26] {
    display: flex;
    border-radius: 2px;
    overflow: hidden;
    flex-grow: 1;
}
.eFBOjU[data-v-20bc7d26] {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 1.5em;
    height: 30px;
    color: rgb(255, 255, 255);
    background: rgb(0, 0, 0);
    border: 0px;
    cursor: pointer;
    touch-action: manipulation;
    font-size: 18px;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 1px 0px;
    padding: 0.5em;
}
.derQBl[data-v-20bc7d26] {
    padding: 0.5em 0.25em;
    border: 0px;
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    text-align: center;
    color: rgb(102, 102, 102);
    font-size: 14px;
    width: 3em;
    flex-grow: 1;
    min-width: 0px;
    height: 30px;
}
.ldfEmf[data-v-20bc7d26] {
    padding: 0.5em 0.25em;
    border: 0px;
    font-size: 14px;
    text-align: center;
    width: 6.6em;
    color: rgb(102, 102, 102);
    background: rgb(237, 240, 247);
    border-radius: 2px;
}
.OtzUb[data-v-20bc7d26] {
    border-radius: 3px 0px 0px 3px;
    padding: 5px 20px;
    position: absolute;
    right: -10px;
    top: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: rgb(218, 237, 255);
    display: flex;
    font-family: "Open Sans", sans-serif;

    font-size: 12px;

    min-height: 44px;
    flex-wrap: wrap;
}
.OtzUb.lay[data-v-20bc7d26] {
    background: rgb(255, 214, 214);
}
.OtzUb span[data-v-20bc7d26] {
    margin: 0px;
    color: rgb(16, 43, 141);
}
.khGOWT[data-v-20bc7d26] {
    display: flex;
    font-family: "Open Sans", sans-serif;
    color: rgb(16, 43, 141);
    font-size: 12px;
    background-color: rgb(255, 255, 255);
    min-height: 44px;
    flex-wrap: wrap;
    position: relative;
}
.dsdHsz .sc-kGXeez[data-v-20bc7d26] {
    color: rgb(0, 33, 80);
    background: rgb(255, 255, 255);
    z-index: 0;
}
.gFNUZF[data-v-20bc7d26] {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.cQbrHN[data-v-20bc7d26] {
    display: flex;
    margin-bottom: 1em;
    position: relative;
}
.gavpov[data-v-20bc7d26] {
    cursor: pointer;
    padding: 0.25em;
    width: 25%;
}
.cFNLpn[data-v-20bc7d26] {
    font-size: 12px;
    padding: 0.5em;
    text-align: center;
    background-color: rgb(232, 237, 245);
    border: 0.25px solid rgba(138, 158, 197, 0.97);
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgb(51, 51, 51);
}
.ekmHYF[data-v-20bc7d26] {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    align-items: center;
    background: rgb(237, 240, 247);
    justify-content: space-between;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.lnRPqv[data-v-20bc7d26]:not(:last-of-type) {
    margin-right: 1em;
}
.lnRPqv[data-v-20bc7d26] {
    width: 50%;
    display: flex;
}
.dpFnVx[data-v-20bc7d26] {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 0.5em 0.25em;
    border: 1px solid rgb(151, 151, 155);
    color: rgb(255, 255, 255);
    text-shadow: rgba(0, 0, 0, 0) 0px 1px 0px;
    background: rgb(0, 0, 0);
    border-radius: 3px;
}
.eOhnxP[data-v-20bc7d26] {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    font-size: 16px;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 1px 0px;
    padding: 0.5em 0.25em;
    color: rgb(255, 255, 255);
    background: none rgb(190, 190, 193);
    border-radius: 3px;
}
.kqAhsC[data-v-20bc7d26] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 15px;
    background: rgb(255, 214, 214);
}
.lnRPqv[data-v-20bc7d26] {
    width: 50%;
    display: flex;
}
.lnRPqv>*[data-v-20bc7d26] {
    width: 100%;
}
@media screen and (max-width: 575px) {
.cSXNXx[data-v-20bc7d26] {
        font-weight: 600;
        font-size: 12px;
}
}