.card-wrapper {
    width: 40%;

    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    margin-top: 10px;
}

.card-d-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-labels {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.showcards-container {
    display: flex;
    margin: 10px;
}

.single-card-d img {
    height: 40px;
    width: 65px;
    margin: 0 10px;
    top: 25px;
    position: relative;
}