@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Hind:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* @font-face {
    font-family: "Verdana";
    src: url("../fonts/verdana.ttf");
} */
*[data-v-84903790] {
    font-family: "Nunito Sans", sans-serif;
    text-transform: uppercase !important;
}
p[data-v-84903790],
h1[data-v-84903790],
h2[data-v-84903790],
h3[data-v-84903790],
h4[data-v-84903790],
h5[data-v-84903790],
h6[data-v-84903790],
span[data-v-84903790],
td[data-v-84903790],
th[data-v-84903790] {
    -webkit-user-select: none;
            user-select: none;
}
.blinking[data-v-84903790] {
    animation: blinkingText-data-v-84903790 0.8s infinite;
}
@keyframes blinkingText-data-v-84903790 {
0% {
        color: #c2e884;
}
20% {
        color: #c2e884;
}
40% {
        color: #ff7f00;
}
60% {
        color: #ff7f00;
}
80% {
        color: #36cb3b;
}
100% {
        color: #36cb3b;
}
}
@keyframes blinkingHoverText-data-v-84903790 {
0% {
        color: red;
}
20% {
        color: red;
}
40% {
        color: #08c;
}
60% {
        color: #08c;
}
80% {
        color: #000;
}
100% {
        color: #000;
}
}
@keyframes blinkingHoverWhite-data-v-84903790 {
0% {
        color: red;
}
20% {
        color: red;
}
40% {
        color: #ff0;
}
60% {
        color: #ff0;
}
80% {
        color: blue;
}
100% {
        color: blue;
}
}
.custom_button[data-v-84903790] {
    background-color: #ff911f !important;
    color: white;
    font-weight: bold;
}
.col-img img[data-v-84903790] {
    margin-left: 6px;
}
.navbar-toggler svg[data-v-84903790] {
    font-size: 30px;
}
.backbutton[data-v-84903790] {
    background-color: rgb(255, 145, 31) !important;
    color: white;
    font-weight: bold;
}
.resultbutton[data-v-84903790] {
    background-color: rgb(231, 41, 41) !important;
    color: white;
    font-weight: bold;
}
a[data-v-84903790] {
    text-decoration: none;
}
.logo[data-v-84903790] {
    height: 20;
}
.bg-image[data-v-84903790] {
    background-image: url("https://wallpapercave.com/wp/wp3509202.jpg");
}
.loginpage-title[data-v-84903790] {
    text-align: center;
    color: #205685;
    font-size: 6rem;
    font-family: "Roboto", sens-serif;
    text-transform: none !important;
    line-height: 0.9;
}
.loginpage-subtitle[data-v-84903790] {
    text-align: center;
    color: #92c738;
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    line-height: 1.334;
    letter-spacing: 0em;
    text-transform: none !important;
}
.login-input-field[data-v-84903790] {
    width: 100%;
    margin: 10px 0px !important;
label {
        text-transform: none !important;
}
input {
        text-transform: none !important;
}
}
.dashboard-notification[data-v-84903790] {
    font-weight: bold;
    text-align: center;
    font-size: 31px;
    line-height: 3.5rem;
}
.login-button[data-v-84903790] {
    background-color: #205685 !important;
    color: white;
    padding: 8px 22px !important;
    font-size: 0.9375rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 500 !important;
    line-height: 1.75 !important;
    letter-spacing: 0.02857em !important;
}
.theme-dark[data-v-84903790] {
    background-color: #52796f !important;
}
.logo[data-v-84903790] {
    width: 200px;
img {
        width: 100px;
}
}
.bg-light-opacity[data-v-84903790] {
    background-color: #89ebb75c !important;
}
.bg-theme-dark[data-v-84903790] {
    background-color: #52796f !important;
    color: #fff;
}
.bg-theme-light[data-v-84903790] {
    background-color: #46c35f !important;
    color: #fff;
}
.tab-button[data-v-84903790] {
    border-radius: 0px !important;
}
.navbar[data-v-84903790] {
    border-top: 1px solid #46c35f;
}
.nav-link[data-v-84903790] {
    color: #fff !important;
&.active {
        font-weight: 500;
}
}
.lgaai[data-v-84903790] {
    background-color: #52796f !important;
    text-align: center;
}
.casino_lgaai[data-v-84903790] {
    background-color: #72bbef !important;
    text-align: center;
    color: #000000;
}
.no_comm_lgaai[data-v-84903790] {
    background-color: #ff911f !important;
    text-align: center;
}
.khaai[data-v-84903790] {
    background-color: #f76f78 !important;
    text-align: center;
}
.dashboard-icons[data-v-84903790] {
a {
        text-decoration: none;
}
img {
        width: 150px;
}
p {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        color: #000;
}
}
.match-meta[data-v-84903790] {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 0px;
img {
        width: 90px;
        margin-right: 20px;
        transform: scale(0.8);
        transition: all 1s ease-in-out;
}
img {
        transform: scale(1.2);
}
h5 {
        color: #52796f;
        font-size: 18px;
}
p {
        color: #46c35f;
        font-size: 14px;
}
}
.match-row[data-v-84903790] {
    border-bottom: 1px solid #46c35f;
}
.game-bet-data[data-v-84903790] {
    width: 200px;
    text-align: center;
    padding: 20px;
    font-weight: bold;
    color: #fff;
}
.match-bets[data-v-84903790] {
    background: #46666c !important;
    border-color: #46666c !important;
}
.session-bets[data-v-84903790] {
    background: #d00000 !important;
    border-color: #d00000 !important;
}
.topnav[data-v-84903790] {
    padding: 1em;
}
.dashboard-icons img[data-v-84903790] {
    border-radius: 100%;
    width: 170px;
    height: 170px;
    object-fit: cover;
}
.coming-soon[data-v-84903790] {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 0px;
    right: 75px;
    top: -15px;
}
@media only screen and (max-width: 480px) {
.mobile-hide[data-v-84903790] {
        display: none !important;
}
.topnav[data-v-84903790] {
        padding: 0.3em;
}
.logo img[data-v-84903790] {
        width: 35px;
}
.dashboard-icons img[data-v-84903790] {
        border-radius: 100%;
        width: 80px;
        height: 80px;
        object-fit: cover;
}
.game-bet-data[data-v-84903790] {
        font-size: 12px;
        padding: 15px;
}
.match-meta[data-v-84903790] {
        padding: 15px;
}
.coming-soon[data-v-84903790] {
        position: absolute;
        width: 50px !important;
        height: 50px !important;
        border-radius: 0px !important;
        right: 35px;
        top: -15px;
}
}
.vibrate-1[data-v-84903790] {
    animation: vibrate-1-data-v-84903790 0.3s linear infinite both;
}
@keyframes vibrate-1-data-v-84903790 {
0% {
        transform: translate(0);
}
20% {
        transform: translate(-2px, 2px);
}
40% {
        transform: translate(-2px, -2px);
}
60% {
        transform: translate(2px, 2px);
}
80% {
        transform: translate(2px, -2px);
}
100% {
        transform: translate(0);
}
}
.signin-title[data-v-84903790] {
    position: absolute;
    top: -20px;
    background-color: #11733f;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 4px;
    font-size: 20px;
}
.signin-logo[data-v-84903790] {
    height: 130px;
    width: auto;
    margin: auto;
}

/* .login-page {
  // background: url("../img/login-bg.jpeg");
  // background: url("../img/login-bg.jpeg");
  background-size: cover;
} */
#offcanvasNavbar-expand-navbar[data-v-84903790] {
    background-color: #52796f;
    width: 250px;
}
.profile-details[data-v-84903790] {
    font-size: 13px;
}
.bet-price-btn[data-v-84903790] {
    background-color: #52796f !important;
    border-color: #52796f !important;
    margin-right: 4px;
    margin-top: 4px;
    border-radius: 0px;
}
.scoreboard[data-v-84903790] {
    width: 100%;
    height: 60px;
    background: #1d1d1d;
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0px 5px;
    font-size: 13px;
}
.session-team[data-v-84903790] {
    display: flex;
    align-items: center;
    height: 56px;
}
.team2[data-v-84903790],
.team1[data-v-84903790] {
    width: 20%;
    max-width: 20%;
    text-align: center;
    font-weight: bold;
}
.scoreboard span[data-v-84903790] {
    width: 30px;
    height: 30px;
    display: inline-flex;
    background: #fff;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 0px 5px 0px #ccc;
img {
        width: 80%;
}
}
.live-status[data-v-84903790] {
    width: 100px;
    text-align: center;
    border-radius: 30px;
    background: #fff;
    height: 30px;
    color: #000;
    line-height: 2em;
    box-shadow: inset 0px 0px 5px 0px #ccc;
}
.players[data-v-84903790] {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px;
    background: #1d1d1d;
    color: #fff;
    align-items: center;
    font-size: 12px;
div {
        flex: 1 1;
}
}
.text-right[data-v-84903790] {
    text-align: right;
}
ul.rules-list li[data-v-84903790] {
    margin: 5px 0px;
    font-size: 13.5px;
    font-weight: 600;
    letter-spacing: 1.2px;
}
.rules-list[data-v-84903790] {
    font-size: 13.5px;
}
.lgaai span[data-v-84903790],
.khaai span[data-v-84903790] {
    font-size: 12px;
    display: block;
}
.suspended-bg[data-v-84903790] {

    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 100%;
    background-size: contain;
    min-width: 150px;
span {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        background: #ffffffe6;
        width: 100%;
        color: #52796f;
        font-weight: bold;
}
}
.inplay-notification[data-v-84903790] {
    background-color: #52796f;
    color: #e8ff26;
    font-weight: bold;
    margin-bottom: -7px;
    margin-top: -10px;
}
.exposure[data-v-84903790] {
    position: absolute;
    z-index: 999;
    width: auto;
    top: -40px;
    right: 45%;
    margin: auto;
    color: #fff;
    font-weight: 800;
    font-size: 14px;
p {
        margin: 0px;
        line-height: 1.6em;
}
}
.score-msg[data-v-84903790] {
    background-color: #1d1d1d;
    font-weight: bold;
}
.last-over[data-v-84903790] {
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    padding: 4px;
span {
        min-width: 16px;
        margin: 0px 3px;
        display: inline-block;
        text-align: center;
        border-radius: 14px;
        font-size: 10px;
}
}
.profile-title[data-v-84903790] {
    font-weight: 700;
}
.caution-warning[data-v-84903790] {
    text-align: center;
img {
        width: 90px;
}
p {
        font-weight: bold;
        font-size: 20px;
}
}
.box-shadow-0[data-v-84903790] {
    box-shadow: none !important;
    box-shadow: initial !important;
}
.loader[data-v-84903790] {
    top: 0px;
    background: #0000004d;
    left: 0;
    z-index: 99;
img {
        width: 100px;
}
}
.flip-vertical-right[data-v-84903790] {
    animation: flip-vertical-right-data-v-84903790 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
}

/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
@keyframes flip-vertical-right-data-v-84903790 {
0% {
        transform: rotateY(0);
}
100% {
        transform: rotateY(180deg);
}
}
.match-tv[data-v-84903790] {
    width: 100%;
    height: 200px;
}
.match-score[data-v-84903790] {
    width: 100%;
    height: 200px;
}
.tv-toggle[data-v-84903790] {
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: center;
button {
        border-radius: 100% !important;
        background-color: #52796f !important;
        width: 40px;
        height: 40px;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
}
img {
        width: 20px;
        filter: invert(1);
}
}



/**
In Play
**/
.date-time[data-v-84903790] {
    position: relative;
    width: 68px;
    height: 45px;
    border-radius: 5px;
    color: #5c6060;
    text-align: center;
    font-weight: 600;
    background-color: #d1d1d1;
    padding: 6px 1px;
    float: left;
    margin-right: 10px;
    text-transform: uppercase;
}
.in-play[data-v-84903790] {
    position: relative;
    background-image: linear-gradient(to right,
            #e163dc -30%,
            #c43d5d 20%,
            #5142c3 130%);
    color: #a13f7c;
    font-size: 8px;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    text-transform: none;
    text-transform: initial;
}
.animation-box[data-v-84903790] {
    overflow: hidden;
    position: relative;
    float: right;
    width: 73px;
    height: 40px;
    left: 2px;
    top: -4px;
}
.in-play[data-v-84903790]::after,
.in-play[data-v-84903790]::before {
    content: "";
    z-index: 0;
    margin: -5%;
    box-shadow: inset 0 0 0 2px;
    border-radius: 7px;
    animation: clip-data-v-84903790 8s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.in-play[data-v-84903790]::before {
    animation-delay: -4s;
}
.animation-box .flip-animation[data-v-84903790] {
    animation: flip-data-v-84903790 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}
ul[data-v-84903790]:not(.browser-default) {
    padding-left: 0;
    list-style-type: none;
}
ul.collapsible li[data-v-84903790] {
    margin: 0 0 10px;
}
.animation-box .flip-animation li.time_date[data-v-84903790] {
    display: block;
    top: 5px;
    position: relative;
}
.animation-box .flip-animation li[data-v-84903790] {
    color: #fff;
    font-size: 9px;
    font-weight: 700;
    padding: 0;
    height: 39px;
    margin-bottom: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}
ul:not(.browser-default)>li[data-v-84903790] {
    list-style-type: none;
}
.date-time .time[data-v-84903790] {
    font-size: 9px;
    white-space: nowrap;
    text-align: center;
    display: block;
    font-weight: 700;
    color: #5c6060;
}
.animation-box .flip-animation li .date[data-v-84903790],
.animation-box .flip-animation li .time[data-v-84903790] {
    color: #fff !important;
}
.date-time .date[data-v-84903790] {
    font-size: 9px;
    white-space: nowrap;
    text-align: center;
    display: block;
    font-weight: 700;
    color: #5c6060;
}
.in-play .in-play-item[data-v-84903790] {
    display: flex;
    align-items: center;
    top: -px;
    left: 0px;
    position: relative;
}
.in-play .in-play-item .icon-holder-small[data-v-84903790] {
    width: 17px;
    margin-right: 4px;
    top: 0px;
    position: relative;
}
.icon-holder-small[data-v-84903790] {
    width: 24px;
    height: 24px;
    margin: 2px auto auto;
    align-items: center;
    display: flex;
}
@keyframes clip-data-v-84903790 {
0%,
    100% {
        clip: rect(0, 75px, 2px, 0);
}
25% {
        clip: rect(0, 2px, 75px, 0);
}
50% {
        clip: rect(45px, 75px, 75px, 0);
}
75% {
        clip: rect(0, 75px, 75px, 45px);
}
}
@keyframes flip-data-v-84903790 {
0% {
        margin-top: -90px;
}
30% {
        margin-top: -60px;
}
55% {
        margin-top: -60px;
}
75% {
        margin-top: 0;
}
99.99% {
        margin-top: 0;
}
100% {
        margin-top: -90px;
}
}
.last-results[data-v-84903790] {
.results {
        float: right;
li {
            float: left;
            margin: 0px 2px;
            height: 25px;
            width: 25px;
            border-radius: 100%;
            background: #ff911f;
            display: flex;
            color: red;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-size: 14px;
}
.result-yellow {
            color: yellow;
}
}
}
.old-matches-list[data-v-84903790] {
    text-align: center;
    cursor: pointer;
.TeamName {
        width: 100%;
        background: var(--primary);
        font-size: 15px;
        display: grid;
        color: #fff;
a {
            padding: 5px 0px;
            text-decoration: none;
            font-weight: 800;
}
}
.old-match-details a {
        text-decoration: none;
}
.old-match-details a tr td {
        color: #000;
        font-weight: 800;
        font-size: 11px;
        line-height: 1.7em;
}
}
.toggle-tv-old[data-v-84903790] {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px 0px 5px 1px #ccc;
    margin-bottom: 0px;
}
.match-bets-old[data-v-84903790] {
    text-align: center;
    /* color: #fff; */
    font-size: 13px;
    margin-top: -5px;
}
.scoreboard-old[data-v-84903790] {
    font-family: "Verdana", sens-serif;
    border: 2px dotted;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    min-height: 54px;
    justify-content: center;
img {
        height: 80px;
        margin: auto;
}
@media only screen and (max-width: 480px) {
img {
            height: 50px;
            width: 100%;
}
}
}
.ball-status[data-v-84903790] {
    height: 70px;
    background: #0d81e4;
    color: #fff;
    font-size: 30px;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2em;
}
.score-msg-old[data-v-84903790] {
    background-color: #52796f;
    color: white;
    font-size: 15px;
    font-weight: bold;
    padding: 5px 0px;
    margin-top: -5px !important;
}
@media only screen and (max-width: 480px) {
.ball-status[data-v-84903790] {
        font-size: 11px;
        height: 50px;
        line-height: 1.5em;
}
}
.font-17[data-v-84903790] {
span {
        font-size: 17px !important;
}
}
.locked-session[data-v-84903790] {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    background: #00000085;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.cards[data-v-84903790] {
    position: absolute;
    top: 0px;
    z-index: 9;
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
li {
        list-style: none;
        float: left;
        margin-right: 3px;
}
img {
        width: 25px;
}
}
.result-headings[data-v-84903790] {
    display: flex;
    justify-content: space-between;
    background: #52796f;
    padding: 5px 5px;
    color: #fff;
h4 {
        font-size: 13px;
        font-weight: bold;
        margin: 0;
}
}
.card_3d[data-v-84903790] {
    box-shadow: 3px 3px 3px grey;
}
.casino-tabs[data-v-84903790] {
    font-size: 12px !important;
    padding: 4px 10px !important;
&.active {
        border-bottom: 4px solid #d07619 !important;
}
}
.casino-bets-list[data-v-84903790] {
li {
        margin: 3px 5px;
        border: 1px solid #ccc;
        border-radius: 3px;
        font-size: 12px;
        padding: 3px 5px;
h4 {
            font-size: 13px;
            font-weight: 600;
            border-bottom: 1px solid #ccc;
}
.bet {
h4 {
                border-bottom: 0;
                border-bottom: initial;
                margin-bottom: 2px;
}
p {
                margin-bottom: 0px;
}
}
}
}
.bet-result[data-v-84903790] {
h4 {
        font-size: 14px;
        font-weight: 600;
}
}
.bet-cards[data-v-84903790] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
img {
        width: 45px;
}
}
.last-won[data-v-84903790] {
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: #ff911f;
    font-size: 15px;
    font-weight: 600;
    width: 160px;
    display: flex;
    justify-content: space-between;
    margin: 0px;
span {
        border: 2px solid #e8ff22;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: inset 0px 0px 6px 0px #ccc;
        padding: 10px;
}
}
.counter[data-v-84903790] {
    position: absolute;
    right: 2px;
    bottom: 4px;
    color: #ff911f;
    height: 50px;
    font-size: 20px;
    border: 2px solid #e8ff22;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: inset 0px 0px 6px 0px #ccc;
}
.last-results[data-v-84903790] {
&.h-70 {
        height: 70px !important;
}
&.h-100 {
        height: 100px;
}
}
.casino-bet-amounts[data-v-84903790] {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.casino-loader[data-v-84903790] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: #0000004d;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lds-dual-ring[data-v-84903790] {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring[data-v-84903790]:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring-data-v-84903790 1.2s linear infinite;
}
@keyframes lds-dual-ring-data-v-84903790 {
0% {
        transform: rotate(0deg);
}
100% {
        transform: rotate(360deg);
}
}
.statement-tabs[data-v-84903790] {
&.inplay {
button {
            font-size: 10px;
}
}
}
.bet-slip-title[data-v-84903790] {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #fff;
.h4 {
        font-size: 13px !important;
        font-weight: 700 !important;
        color: #fff;
}
}
.match-bets-old thead tr th[data-v-84903790] {
    font-weight: 900;
    line-height: 1em;
    color: #fff;
}
.old-match-bets[data-v-84903790] {
    font-size: 9px;
}
.old-match-bets td[data-v-84903790] {
    font-weight: 900;
    border: 1px solid;
}
.old-match-bets tr[data-v-84903790] {
    border: 1px solid;
}
table.table-bordered.score-msg-old.text-dark.table.text-center tbody tr[data-v-84903790] {
    display: flex;
    justify-content: space-between;
}
table.table-bordered.score-msg-old.text-dark.table.text-center tbody tr th[data-v-84903790] {
    display: flex;
    align-items: center;
    padding: 0px;
    border: 0px;
}
.statement-tables[data-v-84903790] {
thead {
        font-size: 12px;
}
&.table> :not(:first-child) {
        border-top: 0px;
}
}
.statement-tables[data-v-84903790] {
tbody {
tr {
            font-size: 12px;
            font-weight: bold;
            border-color: grey !important;
}
td {
            border-color: grey !important;
}
}
}
.back-btn[data-v-84903790] {
    font-size: 13px !important;
}
.bet-modal[data-v-84903790] {
.close-modal {
        text-align: right;
}
p {
        margin-bottom: 0px;
}
.modal-content {
        background-color: #28284e;
        color: var(--white);
.modal-body {
            padding: 0px;
}
.bet-strip {
            background-color: #ffffff47;
            font-size: 12px;
            padding: 10px 0px;
            text-align: center;
            font-weight: bold;
label {
                color: #ffffffa3;
}
p {
                font-size: 16px;
                color: #fff;
}
}
}
.bet-price-btns {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
}
.bet-price-btn {
        background-color: #ffffff47 !important;
        border-color: --white !important;
        font-size: 12px;
        width: 29%;
        margin: 5px auto;
        border-radius: 20px;
}
}
.inplay-session-bets[data-v-84903790] {
    font-size: 10px;
    font-weight: bold;
}
.notification[data-v-84903790] {
    margin: 0;
    text-align: center;
    background: #52796f;
    color: #fff;
    box-shadow: 0px 4px 18px 0px #cccccc7a;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: -10px;
}
.amar-akbar-anthony[data-v-84903790] {
    font-size: 10px;
.casino-card {
        max-width: 33%;
}
}
.games-types[data-v-84903790] {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
li {
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 4px 0px #ccc;
        max-width: 80px;
        align-items: center;
        padding: 14px 0px;
        font-weight: 800;
        color: #52796f;
        font-size: 11px;
        text-align: center;
        cursor: pointer;
img {
            width: 30px;
}
&.active {
            box-shadow: 0px 0px 6px 0px #0000009e;
}
}
}
.blink[data-v-84903790] {
    animation: blink-data-v-84903790 1s steps(1, end) infinite;
    width: 10px;
    height: 10px;
    background-color: #55d18f;
    border-radius: 100%;
}
.cursor-pointer[data-v-84903790] {
    cursor: pointer;
}
@keyframes blink-data-v-84903790 {
0% {
        opacity: 1;
}
50% {
        opacity: 0;
}
100% {
        opacity: 1;
}
}
.cell-td[data-v-84903790] {
    vertical-align: middle;
    text-align: center;
    color: white;
}
.pdr-4[data-v-84903790] {
    padding-right: 5px;
}
.casino-back-btn[data-v-84903790] {
    background: #ff911f !important;
    bottom: 0;
    color: #fff !important;
    left: 0;
    margin: auto;
    padding: 5px;
    position: fixed;
    right: 0;
    text-align: center;
    text-decoration: none;
}
.TeamName a[data-v-84903790] {
    color: white !important;
}
.teamClrBlue[data-v-84903790] {
    background-color: rgb(114, 187, 239) !important;
    color: white;
}
.teamClrPink[data-v-84903790] {
    background-color: rgb(250, 169, 186) !important;
    color: white;
}
.mtachImgInfo[data-v-84903790] {
    float: right;
    height: 20px;
    font-size: 10px;
    cursor: pointer;
}
.vertical-middle[data-v-84903790] {
    vertical-align: middle;
}
.session-style[data-v-84903790] {
    background-color: rgb(255, 145, 31);
    color: white;
    font-weight: bold;
    margin-top: -15px;
}
.session-plus-minus[data-v-84903790] {
    font-weight: 700;
    padding: 5px;
}
.session-plus-minus p[data-v-84903790] {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0;
}
.statement-tabs[data-v-84903790] {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}
.statement-tabs button[data-v-84903790] {
    border-radius: 0;
    width: 100%;
    background-color: var(--secondary) !important
}
.cstyle[data-v-84903790] {
    position: absolute;
    top: 29px;
    left: 26px;
    font-size: 15px;
    color: gray;
}
.table svg[data-v-84903790] {
    color: white;
    fill: white;
}
.locked-session[data-v-84903790] {
    align-items: center;
    background: #00000085;
    display: flex !important;
    height: 100%;
    justify-content: center;
    left: 0;
}
.cards[data-v-84903790],
.locked-session[data-v-84903790] {
    position: absolute;
    top: 0;
    width: 100%;
}
.cupstyle[data-v-84903790] {
    position: absolute;
    margin-top: -10px;
    margin-left: 6px;
    width: 30px;
}
.btnclr[data-v-84903790] {
    background-color: rgb(0, 45, 91) !important;
    border: white;
    outline: none;
}
.btnclr[data-v-84903790]:focus {
    outline: none;
    box-shadow: none;
}
.header-matka[data-v-84903790] {
    width: 100%;
    background: #52796f;
    vertical-align: baseline;
    font-size: 15px;
    display: grid;
    text-align: center;
    color: #fff;
    padding: 8px;
}
.matka-date h2[data-v-84903790] {
    font-size: 16px;
    margin-top: 8px;
    text-align: center;
    border-bottom: 1px dotted;
    padding-bottom: 7px;
    width: 100%;
}
.header-matka .blink[data-v-84903790] {
    margin-top: 0px;
    margin-left: 5px;
}
.card-matka h2[data-v-84903790] {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}
.card-matka[data-v-84903790] {
    border-radius: 8px;
    margin-bottom: 1rem;
    overflow: hidden;
    padding: 0;
    box-shadow: 1px 2px 3px #80808070;
    border: 1px solid #b0b0b069;
}
.pd2[data-v-84903790] {
    padding: 0rem 2rem;
}
.button-matka[data-v-84903790] {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 1rem;
}
.button-matka .jodi-button[data-v-84903790],
.button-matka .dhai-button[data-v-84903790],
.button-matka .haruf-button[data-v-84903790] {
    background-color: #52796f;
    width: 189px;
    padding: 7px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    box-shadow: 2px 3px 7px 0px #52796f3d;
}
.button-matka .jodi-button[data-v-84903790]:hover,
.button-matka .dhai-button[data-v-84903790]:hover,
.button-matka .haruf-button[data-v-84903790]:hover {
    box-shadow: none;
    opacity: 0.8;
}
.matka-table thead tr th[data-v-84903790] {
    font-weight: 900;
    line-height: 1em;
    color: #fff;
}
.addmatka[data-v-84903790] {
    background-color: #52796f;
    width: 100%;
    padding: 7px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-size: 16px;
}
.deletematka[data-v-84903790] {
    background-color: red;
    width: 100%;
    padding: 7px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-size: 16px;
}
td[data-v-84903790],
th[data-v-84903790] {
    -webkit-user-select: none;
            user-select: none;
    font-size: 12px;
}


/* score card added css*/
.score_card[data-v-84903790] {
    margin-top: 5px;
    color: #9fa3a7;
    padding-bottom: 5px;
    color: white;
}
.score-team[data-v-84903790] {
    text-align: left;
    padding-left: 20px;
}
.score-team-1[data-v-84903790] {
    text-align: right;
    padding-right: 20px;
}
.score-details[data-v-84903790] {
    margin-top: 5px;
    color: #9fa3a7;
    padding-bottom: 5px;
    color: white;
}
.scoremain[data-v-84903790] {
    justify-content: space-around;
    padding: 0;
    text-align: center;
    color: white;
}
.team-name[data-v-84903790] {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: white;
}
.team-score[data-v-84903790] {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: #e9d126;
}
.team-over[data-v-84903790] {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #9fa3a7;
}
.team-crr[data-v-84903790] {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    color: #9fa3a7;
}
.team-message-block[data-v-84903790] {
    background: #000;
    padding: 8px;
    height: 65px;
    border-radius: 15px;
    margin: auto;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.team-message[data-v-84903790] {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #e8ff26;
}
.last-overs[data-v-84903790] {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    color: #9fa3a7;
}
.last-over[data-v-84903790] {
    display: flex;
    justify-content: center;
}
.ball[data-v-84903790] {
    width: 20px;
    height: 20px;
    background: #131e29;
    border-radius: 50%;
    margin-left: 0.75em;
    display: flex;
    padding: 1.25em;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #cfd1d3;
    border: 1px solid #1e2833;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}
.four[data-v-84903790] {
    background: #6c9ce6 !important;
    border: 1px solid #6c9ce6 !important;
}
.six[data-v-84903790] {
    background: #418019 !important;
    border: 1px solid #418019 !important;
}
.wicket[data-v-84903790] {
    background: #aa0707 !important;
    border: 1px solid #aa0707 !important;
}
.last-wicket-block[data-v-84903790] {
    background: #1a242e;
    padding: 10px 0;
}
.bg-main[data-v-84903790] {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #252328;
    font-size: 13px;
}
@media (max-width: 576px) {
.match-bets-old[data-v-84903790] {
        margin-top: -13px;
        position: relative;
}
}
.dashboard[data-v-84903790] {
    padding: 10px;
}
.dashboard .introwatch[data-v-84903790] {
    background-color: #52796f;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    flex: 1 1;
    padding: 10px;
}
.dashboard .introwatch p[data-v-84903790] {
    margin-bottom: 0;
}
.dashboard .introwatch .watch[data-v-84903790] {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.dashboard .matka-round[data-v-84903790] {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #52796f;
    color: #fff;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    border-radius: 20px;
}
.dashboard .matka-round.result-done[data-v-84903790] {
    background-color: #d37676;
}
.dashboard .matka-round p[data-v-84903790] {
    margin-bottom: 0px;
}
.dashboard .matka-round div[data-v-84903790] {
    display: flex;
}
.dashboard .matka-round .number[data-v-84903790] {
    font-size: 40px;
    margin-right: 10px;
}
.dashboard .matka-round .details[data-v-84903790] {
    flex-direction: column;
    text-align: left;
}
.dashboard .matka-round .details .heading[data-v-84903790] {
    font-size: 20px;
}
.dashboard .matka-round .details .subline[data-v-84903790] {
    font-size: 12px;
}
.gameplay .top-bar-container[data-v-84903790] {
    overflow-x: scroll;
}
.gameplay .top-bar-container[data-v-84903790]::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}
.gameplay .top-bar-container .top-bar[data-v-84903790] {
    display: flex;
    justify-content: space-evenly;
    background: #52796f;
    color: #fff;
    padding: 10px;
    margin-bottom: 0;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .top-bar-container .top-bar[data-v-84903790] {
        width: 500px;
}
}
.gameplay .top-bar-container .top-bar li[data-v-84903790] {
    cursor: pointer;
}
.gameplay .top-bar-container .top-bar .active[data-v-84903790] {
    text-decoration: underline;
    text-underline-position: under;
    text-underline-offset: 4px;
    text-decoration-color: #ffc470;
    text-decoration-thickness: 3px;
}
.gameplay .pointer[data-v-84903790] {
    display: flex;
    justify-content: space-around;
    text-align: center;
}
.gameplay .pointer .header[data-v-84903790] {
    color: #52796f;
    font-weight: 800;
}
.gameplay .pointer .point-value[data-v-84903790] {
    color: white;
    font-weight: 800;
}
.gameplay .jodi .main-content[data-v-84903790] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.jodi[data-v-84903790] {
    height: auto;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .jodi .main-content[data-v-84903790] {
        max-height: 600px;
        overflow-y: scroll;
}
}
.gameplay .jodi .main-content h2[data-v-84903790] {
    width: 100%;
    text-align: center;
    color: #fff;
    background: #52796f;
}
.gameplay .jodi .main-content .divj[data-v-84903790] {
    max-width: 15%;
    height: 90px;
    border: 1px solid #52796f;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-bottom: 5px;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .jodi .main-content .divj[data-v-84903790] {
        max-width: 22%;
        height: 100px;
}
}
.gameplay .jodi .main-content .divj h1[data-v-84903790] {
    background-color: #52796f;
    color: #fff;
    margin-bottom: 0;
}
.gameplay .jodi .main-content .divj input[data-v-84903790] {
    width: 100%;
    flex: 1 1;
    text-align: center;
}
.gameplay .jodi .main-content .divj input[data-v-84903790]:focus {
    outline: none;
}
.gameplay .jodi .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .jodi .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .haruf .main-content[data-v-84903790] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .haruf .main-content[data-v-84903790] {
        max-height: 600px;
        overflow-y: scroll;
}
}
.gameplay .haruf .main-content h2[data-v-84903790] {
    width: 100%;
    text-align: center;
    color: #fff;
    background: #52796f;
}
.gameplay .haruf .main-content .divj[data-v-84903790] {
    max-width: 15%;
    height: 90px;
    border: 1px solid #52796f;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-bottom: 5px;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .haruf .main-content .divj[data-v-84903790] {
        max-width: 18%;
        height: 100px;
}
}
.gameplay .haruf .main-content .divj h1[data-v-84903790] {
    background-color: #52796f;
    color: #fff;
    margin-bottom: 0;
}
.gameplay .haruf .main-content .divj input[data-v-84903790] {
    width: 100%;
    flex: 1 1;
    text-align: center;
}
.gameplay .haruf .main-content .divj input[data-v-84903790]:focus {
    outline: none;
}
.gameplay .haruf .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .haruf .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .manual[data-v-84903790] {
    display: flex;
    flex-direction: column;
}
.gameplay .manual .header[data-v-84903790] {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.gameplay .manual .header .inner[data-v-84903790] {
    flex: 1 1;
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.gameplay .manual .header .inner[data-v-84903790]:first-child {
    flex: 1.5 1;
}
.gameplay .manual .header .inner h2[data-v-84903790] {
    margin-bottom: 0;
    font-family: "Nunito Sans", sans-serif;
    text-transform: uppercase !important;
}
.gameplay .manual .main[data-v-84903790] {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
}
.gameplay .manual .main .inner[data-v-84903790] {
    flex: 1 1;
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0;
}
.gameplay .manual .main .inner[data-v-84903790]:first-child {
    flex: 1.5 1;
}
.gameplay .manual .main .inner h2[data-v-84903790] {
    margin-bottom: 0;
    font-family: "Nunito Sans", sans-serif;
    text-transform: uppercase !important;
}
.gameplay .manual .main .inner .input-container[data-v-84903790] {
    max-width: 100%;
}
.gameplay .manual .main .inner .input-container input[data-v-84903790] {
    width: 100%;
    text-align: center;
}
.gameplay .manual .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .manual .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .crossing .form-meter[data-v-84903790] {
    padding: 10px;
    box-shadow: 1px 1px 9px 0px #000;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
}
.gameplay .crossing .form-meter .form-gr[data-v-84903790] {
    display: flex;
    margin-bottom: 15px;
}
.gameplay .crossing .form-meter .form-gr .group[data-v-84903790] {
    margin-right: 10px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
}
.gameplay .crossing .form-meter .form-gr .group input[data-v-84903790] {
    width: 100%;
    font-size: 12px;
    padding: 10px;
}
.gameplay .crossing .form-meter .form-gr .group button[data-v-84903790] {
    flex: 1 1;
}
.gameplay .crossing .form-meter .form-gr .group label[data-v-84903790] {
    font-size: 12px;
}
.gameplay .crossing .form-meter .row[data-v-84903790] {
    flex-direction: row;
    margin-bottom: 10px;
}
.gameplay .crossing .form-meter .row.header[data-v-84903790] {
    padding-bottom: 10px;
    border-bottom: 1px solid #ed9455;
}
.gameplay .crossing .form-meter .row .column[data-v-84903790] {
    flex: 1 1;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}
.gameplay .crossing .form-meter .row .column img[data-v-84903790] {
    width: 20px;
    height: 20px;
}
.gameplay .crossing .form-meter .mid-container[data-v-84903790] {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}
.gameplay .crossing .form-meter .error[data-v-84903790] {
    color: red;
    font-weight: 600;
    font-size: 10px !important;
}
.gameplay .crossing .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .crossing .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .counter-game[data-v-84903790] {
    background: #52796f;
    padding: 10px;
    text-align: center;
    color: #000;
    font-weight: 800;
    margin-bottom: 10px;
    width: 100%;
}
.show-bits[data-v-84903790] {
    margin-top: 20px;
}
.show-bits .react-datepicker-wrapper[data-v-84903790] {
    display: flex;
}
.carousel .thumbs-wrapper[data-v-84903790] {
    display: none;
}
.result-slider[data-v-84903790] {
    margin-top: 15px;
    margin-bottom: 15px;
}
.result-slider .silders[data-v-84903790] {
    border: 1px solid #52796f;
    border-radius: 20px;
    min-height: 100px;
}
.result-slider .silders .header[data-v-84903790] {
    background-color: #52796f;
    padding: 5px;
    color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.result-slider .silders .content[data-v-84903790] {
    color: red;
    font-weight: 700;
    margin-bottom: 4px;
}
.bet-show-container[data-v-84903790] {
    overflow-x: scroll;
}
.bet-show-container[data-v-84903790]::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}
.bet-show-container table[data-v-84903790] {
    width: 523px;
}
.bet-show-container th[data-v-84903790],
.bet-show-container td[data-v-84903790] {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    min-width: 50px;
    font-weight: 800;
}
.bet-show-container th[data-v-84903790] {
    border-bottom: 1px solid red;
}
.dashboard[data-v-84903790] {
    padding: 10px;
}
.dashboard .introwatch[data-v-84903790] {
    background-color: #52796f;
    color: #fff;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    flex: 1 1;
    padding: 10px;
}
.dashboard .introwatch p[data-v-84903790] {
    margin-bottom: 0;
}
.dashboard .introwatch .watch[data-v-84903790] {
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}
.dashboard .matka-round[data-v-84903790] {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #52796f;
    color: #fff;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    border-radius: 20px;
}
.dashboard .matka-round.result-done[data-v-84903790] {
    background-color: #d37676;
}
.dashboard .matka-round p[data-v-84903790] {
    margin-bottom: 0px;
}
.dashboard .matka-round div[data-v-84903790] {
    display: flex;
}
.dashboard .matka-round .number[data-v-84903790] {
    font-size: 40px;
    margin-right: 10px;
}
.dashboard .matka-round .details[data-v-84903790] {
    flex-direction: column;
    text-align: left;
}
.dashboard .matka-round .details .heading[data-v-84903790] {
    font-size: 20px;
}
.dashboard .matka-round .details .subline[data-v-84903790] {
    font-size: 12px;
}
.gameplay .top-bar-container[data-v-84903790] {
    overflow-x: scroll;
}
.gameplay .top-bar-container[data-v-84903790]::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}
.gameplay .top-bar-container .top-bar[data-v-84903790] {
    display: flex;
    justify-content: space-evenly;
    background: #52796f;
    color: #fff;
    padding: 10px;
    margin-bottom: 0;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .top-bar-container .top-bar[data-v-84903790] {
        width: 500px;
}
}
.gameplay .top-bar-container .top-bar li[data-v-84903790] {
    cursor: pointer;
}
.gameplay .top-bar-container .top-bar .active[data-v-84903790] {
    text-decoration: underline;
    text-underline-position: under;
    text-underline-offset: 4px;
    text-decoration-color: #ffc470;
    text-decoration-thickness: 3px;
}
.gameplay .pointer[data-v-84903790] {
    display: flex;
    justify-content: space-around;
    text-align: center;
}
.gameplay .pointer .header[data-v-84903790] {
    color: #52796f;
    font-weight: 800;
}
.gameplay .pointer .point-value[data-v-84903790] {
    color: white;
    font-weight: 800;
}
.gameplay .jodi .main-content[data-v-84903790] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .jodi .main-content[data-v-84903790] {
        max-height: 600px;
        overflow-y: scroll;
}
}
.gameplay .jodi .main-content h2[data-v-84903790] {
    width: 100%;
    text-align: center;
    color: #fff;
    background: #52796f;
}
.gameplay .jodi .main-content .divj[data-v-84903790] {
    max-width: 15%;
    height: 90px;
    border: 1px solid #52796f;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-bottom: 5px;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .jodi .main-content .divj[data-v-84903790] {
        max-width: 22%;
        height: 100px;
}
}
.gameplay .jodi .main-content .divj h1[data-v-84903790] {
    background-color: #52796f;
    color: #fff;
    margin-bottom: 0;
}
.gameplay .jodi .main-content .divj input[data-v-84903790] {
    width: 100%;
    flex: 1 1;
    text-align: center;
}
.gameplay .jodi .main-content .divj input[data-v-84903790]:focus {
    outline: none;
}
.gameplay .jodi .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .jodi .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .haruf .main-content[data-v-84903790] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .haruf .main-content[data-v-84903790] {
        max-height: 600px;
        overflow-y: scroll;
}
}
.gameplay .haruf .main-content h2[data-v-84903790] {
    width: 100%;
    text-align: center;
    color: #fff;
    background: #52796f;
}
.gameplay .haruf .main-content .divj[data-v-84903790] {
    max-width: 15%;
    height: 90px;
    border: 1px solid #52796f;
    text-align: center !important;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-bottom: 5px;
}
@media only screen and (min-width: 375px) and (max-width: 474px) {
.gameplay .haruf .main-content .divj[data-v-84903790] {
        max-width: 18%;
        height: 100px;
}
}
.gameplay .haruf .main-content .divj h1[data-v-84903790] {
    background-color: #52796f;
    color: #fff;
    margin-bottom: 0;
}
.gameplay .haruf .main-content .divj input[data-v-84903790] {
    width: 100%;
    flex: 1 1;
    text-align: center;
}
.gameplay .haruf .main-content .divj input[data-v-84903790]:focus {
    outline: none;
}
.gameplay .haruf .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .haruf .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .manual[data-v-84903790] {
    display: flex;
    flex-direction: column;
}
.gameplay .manual .header[data-v-84903790] {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.gameplay .manual .header .inner[data-v-84903790] {
    flex: 1 1;
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
}
.gameplay .manual .header .inner[data-v-84903790]:first-child {
    flex: 1.5 1;
}
.gameplay .manual .header .inner h2[data-v-84903790] {
    margin-bottom: 0;
    font-family: "Nunito Sans", sans-serif;
    text-transform: uppercase !important;
}
.gameplay .manual .main[data-v-84903790] {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
}
.gameplay .manual .main .inner[data-v-84903790] {
    flex: 1 1;
    border: 1px solid #000;
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0;
}
.gameplay .manual .main .inner[data-v-84903790]:first-child {
    flex: 1.5 1;
}
.gameplay .manual .main .inner h2[data-v-84903790] {
    margin-bottom: 0;
    font-family: "Nunito Sans", sans-serif;
    text-transform: uppercase !important;
}
.gameplay .manual .main .inner .input-container[data-v-84903790] {
    max-width: 100%;
}
.gameplay .manual .main .inner .input-container input[data-v-84903790] {
    width: 100%;
    text-align: center;
}
.gameplay .manual .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .manual .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .crossing .form-meter[data-v-84903790] {
    padding: 10px;
    box-shadow: 1px 1px 9px 0px #000;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 25px;
}
.gameplay .crossing .form-meter .form-gr[data-v-84903790] {
    display: flex;
    margin-bottom: 15px;
}
.gameplay .crossing .form-meter .form-gr .group[data-v-84903790] {
    margin-right: 10px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
}
.gameplay .crossing .form-meter .form-gr .group input[data-v-84903790] {
    width: 100%;
    font-size: 12px;
    padding: 10px;
}
.gameplay .crossing .form-meter .form-gr .group button[data-v-84903790] {
    flex: 1 1;
}
.gameplay .crossing .form-meter .form-gr .group label[data-v-84903790] {
    font-size: 12px;
}
.gameplay .crossing .form-meter .row[data-v-84903790] {
    flex-direction: row;
    margin-bottom: 10px;
}
.gameplay .crossing .form-meter .row.header[data-v-84903790] {
    padding-bottom: 10px;
    border-bottom: 1px solid #ed9455;
}
.gameplay .crossing .form-meter .row .column[data-v-84903790] {
    flex: 1 1;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}
.gameplay .crossing .form-meter .row .column img[data-v-84903790] {
    width: 20px;
    height: 20px;
}
.gameplay .crossing .form-meter .mid-container[data-v-84903790] {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}
.gameplay .crossing .form-meter .error[data-v-84903790] {
    color: red;
    font-weight: 600;
    font-size: 10px !important;
}
.gameplay .crossing .footer[data-v-84903790] {
    display: flex;
    padding: 18px;
}
.gameplay .crossing .footer button[data-v-84903790] {
    flex: 1 1;
    background: #52796f !important;
    border-color: #52796f;
}
.gameplay .counter-game[data-v-84903790] {
    background: #52796f;
    padding: 10px;
    text-align: center;
    color: white;
    font-weight: 800;
    margin-bottom: 10px;
    width: 100%;
}
.show-bits[data-v-84903790] {
    margin-top: 20px;
}
.show-bits .react-datepicker-wrapper[data-v-84903790] {
    display: flex;
}
.carousel .thumbs-wrapper[data-v-84903790] {
    display: none;
}
.result-slider[data-v-84903790] {
    margin-top: 15px;
    margin-bottom: 15px;
}
.result-slider .silders[data-v-84903790] {
    border: 1px solid #52796f;
    border-radius: 20px;
    min-height: 100px;
}
.result-slider .silders .header[data-v-84903790] {
    background-color: #52796f;
    padding: 5px;
    color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.result-slider .silders .content[data-v-84903790] {
    color: white;
    font-weight: 700;
    margin-bottom: 4px;
    padding-left: 10px;
}
.bet-show-container[data-v-84903790] {
    overflow-x: scroll;
}
.bet-show-container[data-v-84903790]::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}
.bet-show-container table[data-v-84903790] {
    width: 523px;
}
.bet-show-container th[data-v-84903790],
.bet-show-container td[data-v-84903790] {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    min-width: 50px;
    font-weight: 800;
}
.bet-show-container th[data-v-84903790] {
    border-bottom: 1px solid red;
}